.uploader-update {
    background-color: #74c53240!important;
}
.uploader-move {
    background-color: rgba(50, 116, 197, 0.25) !important;
}
.uploader-move-analytical {
    background-color: rgba(70, 50, 197, 0.25) !important;
}
.uploader-add {
    background-color: rgba(60, 197, 50, 0.25) !important;
}
.uploader-delete {
    background-color: rgba(197, 50, 72, 0.25) !important;
}
.uploader-update-attribute {
    background-color: rgba(65, 50, 197, 0.25) !important;
}
.uploader-update-tag {
    background-color: rgba(197, 177, 50, 0.25) !important;
}

header a {
    color: white;
}

.color-basis {
    background-color: #336fa1;
}

body {
    background-color: #dbe2e7;
}

.b-1 {
    border-radius: 0.8em;
}

.cursor-pointer {
    cursor: pointer;
}
.code-inline {
    width: min-content;
    padding: 0.25rem;
}

.w-min-content {
    width: min-content;
}
.lang a {
    color: #ffffff;
    font-weight: bold;
    margin-right: 0.5em;
    padding: 0.2em 0.5em;
}
.lang a:not(.active) {
    padding-top: 0.4em;
}

.lang a.active {
    border: 0.2em solid #ffffff;
    border-radius: 1.2em;
}
.c-gap-1 {
    column-gap: 1rem;
}
